export default {
  async update (Vue, id, formData) {
    await Vue.$api.call(
      'parteNovedad.update',
      {
        values: {
          idparte_novedad: id,
          fparte: formData.fparte,
          observaciones: formData.observaciones,
        }
      }
    )
  },
}
