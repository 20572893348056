<template>
  <b10-base>
    <b10-toolbar
      :title="title"
    >
      <v-btn
        slot="right"
        icon
        @click.stop="clickSubmit($refs.form)"
      >
        <v-icon>{{ $vuetify.icons.values.check }}</v-icon>
      </v-btn>
    </b10-toolbar>
    <b10-page-content
      :padding="3"
    >
      <v-form
        ref="form"
        lazy-validation
      >
        <parte-novedad-form
          :id="routeParams.idparte_novedad"
          @change-values="changeFormData"
        />
      </v-form>
    </b10-page-content>
  </b10-base>
</template>

<script>
import { formPageMixin } from '@/mixins/formPageMixin'
import ParteNovedadForm from '../components/ParteNovedadForm'
import Data from './ParteNovedadEditData'

export default {
  components: {
    ParteNovedadForm
  },
  mixins: [formPageMixin],
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      this.title = 'Modificar parte de novedad'
    },
    changeFormData (formData) {
      this.formData = formData
    },
    async submitForm () {
      await Data.update(this, this.routeParams.idparte_novedad, this.formData)
      await this.$dirty.modified(this.$dirty.ENTITIES.remote.parteNovedad, this.routeParams.idparte_novedad)
      this.$appRouter.go(-1)
    },
  },
}
</script>
